<template>
  <div class="edit-investment">
    <v-snackbar v-model="snackbar_update" :timeout="3000" top color="success">
      <span>Update successful.</span>
      <v-btn @click="snackbar_update = false" text dark>Close</v-btn>
    </v-snackbar>

    <v-container style="max-width:1000px">
      <h1 class="mt-6 text-center">Edit Investment - {{ loadedInvestment.company_name }}</h1>
      <!-- TODO: Turn the mobile menu into dropdown vs. tabs -->
      <v-tabs
        :grow="false"
        :show-arrows="true"
        :vertical="!isMobile"
        v-model="active_tab"
        background-color="grey lighten-4"
      >
        <!-- Primary info -->
        <v-tab v-for="(tab,index) in tabs" :key="index">{{tab.name}}</v-tab>

        <!-- Primary Info -->
        <v-tab-item>
          <v-row justify="center">
            <v-container class="mx-3 px-2 py-0" style="max-width:600px">
              <v-col>
                <v-alert
                  type="info"
                  v-if="isConverted"
                >You indicated this security has converted to stock (see Conversions tab). Ensure you are updating the most recent type of security for this investment.</v-alert>
                <v-form ref="primaryInfo">
                  <v-card-text>
                    <v-row align="center" class="px-3">
                      <v-text-field
                        prepend-icon="assignment_ind"
                        label="Company Name"
                        v-model="companyName"
                        :rules="requiredField"
                        outlined
                        :hide-details="true"
                        :class="botSpacing"
                        style="max-width:250px"
                        :disabled="true"
                      />
                      <v-btn
                        dark
                        class="tertiary mx-3 mb-3"
                        @click="editCompany"
                      >Edit Company details</v-btn>
                    </v-row>
                    <v-text-field
                      label="Investment Amount"
                      v-model="investmentAmount"
                      prepend-icon="attach_money"
                      prefix="$"
                      type="number"
                      min="0"
                      onkeypress="return event.charCode != 45"
                      :rules="requiredField"
                      outlined
                      :hide-details="true"
                      :class="botSpacing"
                      style="max-width:250px"
                    />
                    <v-row align="center" class="px-3">
                      <v-text-field
                        :disabled="isExited"
                        prepend-icon="trending_up"
                        label="Estimated Value"
                        prefix="$"
                        type="number"
                        min="0"
                        onkeypress="return event.charCode != 45"
                        v-model="estValue"
                        outlined
                        :hide-details="true"
                        :class="botSpacing"
                        style="max-width:250px"
                      />
                      <v-tooltip v-if="isExited" bottom max-width="300px">
                        <template v-slot:activator="{ on }">
                          <v-icon color="primary" v-on="on">help_outline</v-icon>
                        </template>
                        <span>An investment that has exited no longer has an estimated value. Please enter details on the "Exit Event" tab to calculate the return.</span>
                      </v-tooltip>
                      <span class="mb-3 mx-3">
                        ROI =
                        <v-chip
                          v-if="estROI"
                          :color="getColor(estROI)"
                          dark
                          class="mb-2"
                        >{{ estROI.toFixed(1) }} x</v-chip>
                        <v-spacer />IRR =
                        <v-chip
                          v-if="estIRR"
                          :color="getIRRColor(estIRR)"
                          dark
                        >{{ estIRR.toFixed(1) }} %</v-chip>
                        <v-tooltip bottom max-width="300px">
                          <template v-slot:activator="{ on }">
                            <v-icon color="primary" v-on="on">help_outline</v-icon>
                          </template>
                          <span>The estimated Internal Rate of Return (IRR) depends on four inputs: the Cost Basis (Purchase Value), Estimated Value, Current Date, and the Original Purchase Date.</span>
                        </v-tooltip>
                        <v-spacer />
                      </span>
                    </v-row>
                    <v-combobox
                      prepend-icon="business"
                      label="Funding Portal"
                      :items="[...loadedInvestments,{portal: 'WeFunder'},{portal: 'StartEngine'},{portal: 'Republic'},{portal: '(Or type to add new)'}]"
                      item-text="portal"
                      item-value="portal"
                      v-model="fundingPortal"
                      :rules="requiredField"
                      :return-object="false"
                      outlined
                      :hide-details="true"
                      :class="botSpacing"
                      style="max-width:250px"
                    />
                    <v-row align="center" class="px-3">
                      <v-combobox
                        prepend-icon="assignment"
                        label="Security Type"
                        :items="securityTypes"
                        v-model="securityType"
                        :rules="requiredField"
                        outlined
                        :hide-details="true"
                        :class="botSpacing"
                        style="max-width:250px"
                      />
                      <v-btn
                        v-if="!isEquity"
                        class="mx-3 mb-3 tertiary"
                        dark
                        @click="gotoConversionsTab"
                      >Record a Conversion</v-btn>
                    </v-row>
                    <v-row align="center" class="px-3">
                      <v-menu
                        v-model="menu2"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            :value="mainDate"
                            v-if="!primaryDetails.date"
                            label="Investment Date"
                            hint="YYYY-MM-DD format"
                            persistent-hint
                            prepend-icon="event"
                            readonly
                            @input="this.primaryDetails.date = $event.target.value"
                            v-on="on"
                            outlined
                            :class="botSpacing"
                            style="max-width:250px"
                          />
                          <v-text-field
                            v-else
                            v-model="primaryDetails.date"
                            label="Investment Date"
                            hint="YYYY-MM-DD format"
                            persistent-hint
                            readonly
                            prepend-icon="event"
                            v-on="on"
                            outlined
                            :class="botSpacing"
                            style="max-width:250px"
                          />
                        </template>
                        <v-date-picker v-model="mainDate" no-title min="1990-01-01" />
                      </v-menu>
                      <v-spacer />
                      <v-checkbox
                        :disabled="isFailed || primaryDetails.isFailed"
                        label="Exit?"
                        v-model="isExited"
                        class="mb-7"
                      />
                      <v-spacer />
                      <v-btn
                        v-if="isExited"
                        class="mb-8 secondary"
                        @click="gotoExitTab"
                      >Exit Details</v-btn>
                      <v-checkbox
                        :disabled="primaryDetails.isExited"
                        v-else
                        label="Failed?"
                        v-model="isFailed"
                        class="mb-7"
                      />
                      <v-spacer />
                    </v-row>
                    <v-card-actions>
                      <v-spacer />
                      <v-btn
                        class="primary"
                        @click="primaryUpdate"
                        :disabled="!primaryChanged"
                        :loading="isLoading"
                      >
                        <v-icon>save</v-icon>Save Changes
                      </v-btn>
                      <v-spacer />
                    </v-card-actions>
                  </v-card-text>
                </v-form>
              </v-col>
            </v-container>
          </v-row>
        </v-tab-item>
        <!-- More Info -->
        <v-tab-item>
          <v-row justify="center">
            <v-container class="mx-3 px-2 py-0" style="max-width:600px">
              <v-col>
                <v-form ref="additionalInfo">
                  <v-card-text>
                    <v-checkbox label="Secondary Offering?" v-model="isSecondary" />
                    <v-row class="px-3">
                      <v-combobox
                        label="Investment Account"
                        :items="['401k', 'IRA', 'Taxable', 'Type to add other']"
                        v-model="accountType"
                        prepend-icon="laptop_mac"
                        outlined
                        :hide-details="true"
                        :class="botSpacing"
                        style="max-width:200px"
                      />
                    </v-row>
                    <v-row class="px-3">
                      <v-text-field
                        label="Investment Fees"
                        v-model="dealFees"
                        prepend-icon="attach_money"
                        prefix="$"
                        type="number"
                        min="0"
                        onkeypress="return event.charCode != 45"
                        outlined
                        :hide-details="true"
                        :class="botSpacing"
                        style="max-width:200px"
                      />
                      <v-tooltip bottom max-width="300px">
                        <template v-slot:activator="{ on }">
                          <v-icon class="mb-3" color="primary" v-on="on">help_outline</v-icon>
                        </template>
                        <span>The IRS does not let you write off fees of investing, but you may be able to include investment fees in the purchase price of the asset as the total cost basis.</span>
                      </v-tooltip>
                    </v-row>
                    <v-row class="px-3">
                      <v-combobox
                        label="Exemption Type"
                        :items="['Reg CF', 'Reg A+', 'Reg D']"
                        v-model="regType"
                        prepend-icon="gavel"
                        outlined
                        :hide-details="true"
                        :class="botSpacing"
                        style="max-width:200px"
                      />
                      <v-tooltip bottom max-width="300px">
                        <template v-slot:activator="{ on }">
                          <v-icon class="mb-3" color="primary" v-on="on">help_outline</v-icon>
                        </template>
                        <span>Record the exemption type of each transaction so you can monitor your 12-month investment limits under Reg CF and Reg A+. This will update the value on the Dashboard page.</span>
                      </v-tooltip>
                    </v-row>
                    <v-row class="px-3">
                      <v-combobox
                        label="Deal Status"
                        :items="['Preliminary','Committed','Completed','Cancelled']"
                        v-model="dealStatus"
                        prepend-icon="av_timer"
                        outlined
                        :hide-details="true"
                        :class="botSpacing"
                        style="max-width:200px"
                      />
                    </v-row>
                    <v-row class="px-3">
                      <v-text-field
                        label="Perks"
                        v-model="perks"
                        prepend-icon="card_giftcard"
                        outlined
                        :hide-details="true"
                        :class="botSpacing"
                      />
                      <v-checkbox label="Perks Fulfilled?" v-model="perkStatus" />
                    </v-row>
                    <v-textarea
                      label="Other Notes"
                      v-model="dealNotes"
                      prepend-icon="note"
                      outlined
                      :hide-details="true"
                      :class="botSpacing"
                    />

                    <v-card-actions>
                      <v-spacer />

                      <v-btn
                        class="primary"
                        @click="moreUpdate"
                        :disabled="!moreChanged"
                        :loading="isLoading"
                      >
                        <v-icon>save</v-icon>Save Changes
                      </v-btn>
                    </v-card-actions>
                  </v-card-text>
                </v-form>
              </v-col>
            </v-container>
          </v-row>
        </v-tab-item>
        <!-- Deal Terms -->
        <v-tab-item>
          <v-row justify="center">
            <v-container class="mx-3 px-2 py-0" style="max-width:600px">
              <v-col>
                <v-form ref="dealTerms">
                  <v-card-text>
                    <v-row class="px-3">
                      <v-combobox
                        label="Security Type"
                        :items="securityTypes"
                        v-model="securityTypeTerms"
                        :rules="requiredField"
                        outlined
                        :hide-details="true"
                        :class="botSpacing"
                      />
                      <v-spacer />
                      <v-checkbox v-model="earlyBird" label="Early-Bird Terms?" />
                    </v-row>

                    <v-card class="px-5 mb-2">
                      <!-- SAFE Terms -->
                      <v-card-text v-if="isSAFE">
                        <div class="overline mb-4">SAFE Terms</div>
                        <v-row>
                          <v-text-field
                            label="Valuation Cap"
                            v-model="valCap"
                            prefix="$"
                            type="number"
                            min="0"
                            suffix="million"
                            onkeypress="return event.charCode != 45"
                            style="max-width: 230px"
                            outlined
                            :hide-details="true"
                            :class="botSpacing"
                          />
                          <v-spacer />
                          <v-text-field
                            label="Discount Rate (%)"
                            v-model="discountRate"
                            outlined
                            type="number"
                            suffix="%"
                            min="0"
                            onkeypress="return event.charCode != 45"
                            style="max-width: 230px"
                            :hide-details="true"
                            :class="botSpacing"
                          />
                        </v-row>
                        <v-checkbox v-model="mfnClause" class="my-0">
                          <template v-slot:label>
                            MFI / MFN Clause?
                            <v-tooltip bottom max-width="300px">
                              <template v-slot:activator="{ on }">
                                <v-icon color="primary" v-on="on">help_outline</v-icon>
                              </template>
                              <span>Most Favored Investor (MFI) or Most Favored Nation (MFN) clause. These typically give investors the best negotiated deal terms of any follow-on investors. Most common on WeFunder on uncapped SAFEs.</span>
                            </v-tooltip>
                          </template>
                        </v-checkbox>
                      </v-card-text>

                      <!-- Convertible Note Terms -->
                      <v-card-text v-else-if="isNote">
                        <div class="overline mb-4">Convertible Note Terms</div>
                        <v-row>
                          <v-text-field
                            label="Valuation Cap"
                            v-model="valCap"
                            prefix="$"
                            type="number"
                            min="0"
                            suffix="million"
                            onkeypress="return event.charCode != 45"
                            style="max-width: 230px"
                            outlined
                            :hide-details="true"
                            :class="botSpacing"
                          />
                          <v-spacer />
                          <v-text-field
                            label="Discount Rate (%)"
                            v-model="discountRate"
                            type="number"
                            min="0"
                            suffix="%"
                            onkeypress="return event.charCode != 45"
                            style="max-width: 230px"
                            outlined
                            :hide-details="true"
                            :class="botSpacing"
                          />
                        </v-row>
                        <v-row>
                          <v-menu
                            v-model="matDateMenu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                :value="pickedDate"
                                v-if="!terms.maturityDate"
                                label="Maturity Date"
                                hint="YYYY-MM-DD format"
                                persistent-hint
                                prepend-inner-icon="event"
                                style="max-width: 230px"
                                readonly
                                @input="this.terms.maturityDate = $event.target.value"
                                v-on="on"
                                outlined
                                :class="botSpacing"
                              />
                              <v-text-field
                                v-else
                                v-model="terms.maturityDate"
                                label="Maturity Date"
                                hint="YYYY-MM-DD format"
                                persistent-hint
                                readonly
                                prepend-inner-icon="event"
                                style="max-width: 230px"
                                v-on="on"
                                outlined
                                :class="botSpacing"
                              />
                            </template>
                            <v-date-picker v-model="pickedDate" no-title min="1990-01-01" />
                          </v-menu>
                          <v-spacer />
                          <v-text-field
                            label="Interest Rate (%)"
                            v-model="interestRate"
                            type="number"
                            min="0"
                            suffix="%"
                            style="max-width: 230px"
                            onkeypress="return event.charCode != 45"
                            outlined
                            :hide-details="true"
                            :class="botSpacing"
                          />
                        </v-row>
                        <v-checkbox v-model="mfnClause" class="my-0">
                          <template v-slot:label>
                            MFI / MFN Clause?
                            <v-tooltip bottom max-width="300px">
                              <template v-slot:activator="{ on }">
                                <v-icon color="primary" v-on="on">help_outline</v-icon>
                              </template>
                              <span>Most Favored Investor (MFI) or Most Favored Nation (MFN) clause. These typically give investors the best negotiated deal terms of any follow-on investors. Most common on WeFunder on uncapped SAFEs.</span>
                            </v-tooltip>
                          </template>
                        </v-checkbox>
                      </v-card-text>

                      <!-- Equity Terms -->
                      <v-card-text v-else-if="isEquity">
                        <div class="overline mb-4">Equity Terms</div>
                        <v-row>
                          <v-text-field
                            label="Price per Share"
                            v-model="sharePrice"
                            prefix="$"
                            type="number"
                            min="0"
                            onkeypress="return event.charCode != 45"
                            style="max-width: 230px"
                            outlined
                            :hide-details="true"
                            :class="botSpacing"
                          />
                          <v-spacer />
                          <v-text-field
                            label="Number of Shares"
                            v-model="numShares"
                            type="number"
                            min="0"
                            onkeypress="return event.charCode != 45"
                            outlined
                            :hide-details="true"
                            :class="botSpacing"
                          />
                        </v-row>
                        <v-row>
                          <v-text-field
                            label="Pre-Money Val."
                            v-model="preMoney"
                            prefix="$"
                            type="number"
                            min="0"
                            suffix="million"
                            onkeypress="return event.charCode != 45"
                            style="max-width: 230px"
                            outlined
                            :hide-details="true"
                            :class="botSpacing"
                          />
                          <v-spacer />
                          <v-text-field
                            label="Post-Money Val."
                            v-model="postMoney"
                            prefix="$"
                            type="number"
                            min="0"
                            suffix="million"
                            onkeypress="return event.charCode != 45"
                            style="max-width: 230px"
                            outlined
                            :hide-details="true"
                            :class="botSpacing"
                          />
                        </v-row>
                        <v-row>
                          <v-checkbox v-model="votingRights" label="Voting Rights?" />
                          <v-spacer />
                          <v-checkbox
                            v-model="liquidationPreference"
                            label="Liquidation Preference?"
                          />
                        </v-row>
                      </v-card-text>

                      <!-- Rev Share Terms  -->
                      <v-card-text v-else-if="isRevShare">
                        <div class="overline mb-4">Revenue Share Terms</div>
                        <v-row>
                          <v-text-field
                            label="Rev Share (%)"
                            v-model="revSharePercent"
                            type="number"
                            min="0"
                            suffix="%"
                            onkeypress="return event.charCode != 45"
                            outlined
                            :hide-details="true"
                            :class="botSpacing"
                          />
                          <v-spacer />
                          <v-combobox
                            label="Rev Share Frequency"
                            :items="['Annual','Quarterly','Other']"
                            v-model="revShareFreq"
                            outlined
                            :hide-details="true"
                            :class="botSpacing"
                          />
                        </v-row>
                        <v-row justify="center">
                          <v-text-field
                            label="Repayment Cap (e.g. 3X)"
                            v-model="repaymentCap"
                            type="number"
                            min="0"
                            suffix="X"
                            onkeypress="return event.charCode != 45"
                            outlined
                            :hide-details="true"
                            :class="botSpacing"
                          />
                          <v-spacer />
                          <v-checkbox v-model="isSecured" class="my-0">
                            <template v-slot:label>
                              Secured Loan?
                              <v-tooltip bottom max-width="300px">
                                <template v-slot:activator="{ on }">
                                  <v-icon color="primary" v-on="on">help_outline</v-icon>
                                </template>
                                <span>Some Revenue Share loans will be secured by the business' assets.</span>
                              </v-tooltip>
                            </template>
                          </v-checkbox>
                          <v-spacer />
                        </v-row>
                      </v-card-text>
                    </v-card>

                    <!-- Other Terms -->
                    <v-expansion-panels>
                      <v-expansion-panel>
                        <v-expansion-panel-header>Custom Terms</v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-row
                            v-for="customField in loadedCustomFields"
                            :key="customField.fieldID"
                          >
                            <v-text-field
                              label="Custom Field"
                              :value="customField.label"
                              @input="updateCustomFieldLabel($event,customField.fieldID)"
                              outlined
                              :hide-details="true"
                              :class="botSpacing"
                            />
                            <v-spacer />
                            <v-text-field
                              label="Custom Value"
                              :value="customField.value"
                              @input="updateCustomFieldValue($event,customField.fieldID)"
                              outlined
                              :hide-details="true"
                              :class="botSpacing"
                            />
                            <v-spacer />
                            <v-btn
                              x-small
                              fab
                              color="error"
                              @click="deleteCustomField(customField.fieldID)"
                              :loading="isLoading"
                            >
                              <v-icon dark>mdi-minus</v-icon>
                            </v-btn>
                          </v-row>
                          <v-row align="center" justify="center" class="mb-3">
                            <v-btn
                              small
                              fab
                              color="primary"
                              @click="newField"
                              class="mr-2"
                              :loading="isLoading"
                            >
                              <v-icon dark>mdi-plus</v-icon>
                            </v-btn>Add Custom Terms
                          </v-row>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>

                    <v-card class="px-5 mb-2">
                      <v-card-text>
                        <div class="overline mb-4">Other Terms</div>
                        <v-row>
                          <v-col class="py-0">
                            <v-checkbox v-model="repurchaseRights" class="my-0">
                              <template v-slot:label>
                                Repurchase Rights
                                <v-tooltip bottom max-width="300px">
                                  <template v-slot:activator="{ on }">
                                    <v-icon color="primary" v-on="on">help_outline</v-icon>
                                  </template>
                                  <span>Repurchase rights may be found on SAFE and Convertible Note deal terms. These are typically not favorable to the investor, as it allows the founders to purchase the securities back (at fair market value) if necessary.</span>
                                </v-tooltip>
                              </template>
                            </v-checkbox>
                          </v-col>
                          <v-col class="py-0">
                            <v-checkbox v-model="proRata" class="my-0">
                              <template v-slot:label>
                                Pro Rata
                                <v-tooltip bottom max-width="300px">
                                  <template v-slot:activator="{ on }">
                                    <v-icon color="primary" v-on="on">help_outline</v-icon>
                                  </template>
                                  <span>Pro rata rights are rare in crowdfunding deals. Pro rata gives an investor the option (but not obligation) to invest during follow-on financing rounds to maintain their percentage ownership. This is more typical for angels who write larger checks.</span>
                                </v-tooltip>
                              </template>
                            </v-checkbox>
                          </v-col>
                        </v-row>

                        <v-textarea
                          label="Other Deal Term Notes"
                          v-model="dealTermsNotes"
                          outlined
                          :hide-details="true"
                          :class="botSpacing"
                        />
                      </v-card-text>
                    </v-card>
                    <v-card-actions>
                      <v-spacer />

                      <v-btn
                        class="primary"
                        @click="termsUpdate"
                        :disabled="!termsChanged"
                        :loading="isLoading"
                      >
                        <v-icon>save</v-icon>Save Changes
                      </v-btn>
                    </v-card-actions>
                  </v-card-text>
                </v-form>
              </v-col>
            </v-container>
          </v-row>
        </v-tab-item>
        <!-- Due Diligence -->
        <v-tab-item>
          <v-row justify="center">
            <v-container class="mx-3 px-2 py-0" style="max-width:800px">
              <!-- Team -->
              <v-col>
                <v-form ref="ddForm">
                  <v-card-text>
                    <v-row align="center">
                      <v-col sm="12" md="8" class="pb-0">
                        <v-textarea
                          label="Team"
                          v-model="teamNotes"
                          prepend-icon="people"
                          outlined
                          :hide-details="true"
                        />
                      </v-col>
                      <v-col sm="12" md="4" class="pt-0">
                        <div align="center">
                          <v-rating
                            v-model="teamRating"
                            color="primary"
                            background-color="primary"
                          />
                        </div>
                      </v-col>
                    </v-row>

                    <!-- Tech -->
                    <v-row align="center">
                      <v-col sm="12" md="8" class="pb-0">
                        <v-textarea
                          label="Technology / Solution"
                          v-model="techNotes"
                          prepend-icon="smartphone"
                          outlined
                          :hide-details="true"
                        />
                      </v-col>
                      <v-col sm="12" md="4" class="pt-0">
                        <div align="center">
                          <v-rating
                            v-model="techRating"
                            color="primary"
                            background-color="primary"
                          />
                        </div>
                      </v-col>
                    </v-row>

                    <!-- Total Addressable Market -->
                    <v-row align="center">
                      <v-col sm="12" md="8" class="pb-0">
                        <v-textarea
                          label="TAM"
                          v-model="tamNotes"
                          prepend-icon="pie_chart"
                          outlined
                          :hide-details="true"
                        />
                      </v-col>
                      <v-col sm="12" md="4" class="pt-0">
                        <div align="center">
                          <v-rating v-model="tamRating" color="primary" background-color="primary" />
                        </div>
                      </v-col>
                    </v-row>

                    <!-- Traction -->
                    <v-row align="center">
                      <v-col sm="12" md="8" class="pb-0">
                        <v-textarea
                          label="Traction"
                          v-model="tractionNotes"
                          prepend-icon="directions_car"
                          outlined
                          :hide-details="true"
                        />
                      </v-col>
                      <v-col sm="12" md="4" class="pt-0">
                        <div align="center">
                          <v-rating
                            v-model="tractionRating"
                            color="primary"
                            background-color="primary"
                          />
                        </div>
                      </v-col>
                    </v-row>

                    <!-- Terms -->
                    <v-row align="center">
                      <v-col sm="12" md="8" class="pb-0">
                        <v-textarea
                          label="Terms"
                          v-model="termsNotes"
                          prepend-icon="ballot"
                          outlined
                          :hide-details="true"
                        />
                      </v-col>
                      <v-col sm="12" md="4" class="pt-0">
                        <div align="center">
                          <v-rating
                            v-model="termsRating"
                            color="primary"
                            background-color="primary"
                          />
                        </div>
                      </v-col>
                    </v-row>

                    <v-card-actions>
                      <v-spacer />
                      <v-btn
                        class="primary"
                        @click="ddUpdate"
                        :disabled="!ddChanged"
                        :loading="isLoading"
                      >
                        <v-icon>save</v-icon>Save Changes
                      </v-btn>
                    </v-card-actions>
                  </v-card-text>
                </v-form>
              </v-col>
            </v-container>
          </v-row>
        </v-tab-item>

        <!-- Taxes -->
        <v-tab-item>
          <v-row justify="center">
            <v-container class="mx-3 px-2 py-0" style="max-width:600px">
              <v-col>
                <v-alert
                  type="warning"
                >EXPERIMENTAL: This is to aid investors and is for reference only. This does not cover every tax situation. VentureWallet does not provide tax advice. Always consult with your personal tax advisor or accountant.</v-alert>
                <span v-if="isSecondary">
                  <v-alert
                    type="error"
                  >This investment is disqualified from any QSBS / SBC exemptions because you purchased it via a secondary sale.</v-alert>
                </span>
                <v-form ref="taxForm">
                  <v-row>
                    <v-menu
                      v-model="taxDateMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          :value="pickedTaxDate"
                          v-if="!taxes.purchaseDate"
                          label="Original Purchase Date"
                          hint="YYYY-MM-DD format"
                          persistent-hint
                          prepend-inner-icon="event"
                          readonly
                          @input="this.taxes.purchaseDate = $event.target.value"
                          v-on="on"
                          outlined
                          :class="botSpacing"
                        />
                        <v-text-field
                          v-model="taxes.purchaseDate"
                          v-else
                          label="Original Purchase Date"
                          hint="YYYY-MM-DD format"
                          persistent-hint
                          readonly
                          prepend-inner-icon="event"
                          v-on="on"
                          outlined
                          :class="botSpacing"
                        />
                      </template>
                      <v-date-picker v-model="pickedTaxDate" no-title min="1990-01-01" />
                    </v-menu>
                    <v-spacer />
                    <v-checkbox v-model="is1045">
                      <template v-slot:label>
                        1045 Rollover?
                        <v-tooltip bottom max-width="300px">
                          <template v-slot:activator="{ on }">
                            <v-icon color="primary" v-on="on">help_outline</v-icon>
                          </template>
                          <span>If this stock was a reinvestment under Section 1045, indicate here and select the original purchase date of the prior QSBS investment. The prior holding period counts towards the 5-year QSBS holding period.</span>
                        </v-tooltip>
                      </template>
                    </v-checkbox>
                    <v-spacer />
                  </v-row>
                  <v-row>
                    <v-autocomplete
                      v-if="is1045Rollover"
                      label="What Company is this Rollover From"
                      :items="soldInvestments"
                      item-text="company_name"
                      item-value="id"
                      v-model="priorRolloverObj"
                      :return-object="true"
                      no-data-text="No exited companies. Record an exit to link here."
                      outlined
                    />
                  </v-row>
                  <v-expansion-panels>
                    <!-- QSBS Section 1202 and 1045 -->
                    <v-expansion-panel>
                      <v-expansion-panel-header>
                        <v-row no-gutters>
                          <v-col cols="8">QSBS - Section 1202 (Gains), 1045 (Rollover)</v-col>
                          <v-col cols="2">
                            <v-tooltip bottom max-width="300px">
                              <template v-slot:activator="{ on }">
                                <v-icon color="primary" v-on="on">help_outline</v-icon>
                              </template>
                              <span>
                                <p>Qualified Small Business Stock (QSBS)</p>
                                <p>Section 1202 Gains: 100% exemption on QSBS gains (up to $10M or 10X cost basis) if held for more than 5 years.</p>
                                <p>Section 1045 Rollover: QSBS may be rolled over tax free if held for at least 6 months and reinvested into qualifying QSBS within 60 days of the sale.</p>Always consult with your tax professional or advisor as individual tax situations always differ.
                              </span>
                            </v-tooltip>
                          </v-col>
                          <v-col cols="2">
                            <v-tooltip bottom max-width="300px">
                              <template v-slot:activator="{ on }">
                                <v-icon color="warning" v-on="on">warning</v-icon>
                              </template>
                              <span>Always consult with your tax professional or advisor as individual tax situations always differ. These requirements may not cover all nuances and situations.</span>
                            </v-tooltip>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        All of the conditions below must be met in order to potentially qualify as QSBS under IRC Section 1202. If held longer than 6 months but fewer than 5 years, QSBS may qualify for tax-deferred rollover under Section 1045.
                        <v-checkbox v-model="domesticCorp">
                          <template v-slot:label>
                            Domestic C-Corporation
                            <v-tooltip bottom max-width="300px">
                              <template v-slot:activator="{ on }">
                                <v-icon color="primary" v-on="on">help_outline</v-icon>
                              </template>
                              <span>The security must be equity in a Domestic C-Corporation. It cannot be a pre-money SAFE, Convertible Note, etc. It also cannot be an investment in an LLC or S-Corp. Note: the new YC post-money SAFEs since September 2018 may be treated as Common Stock under IRC Section 1202.</span>
                            </v-tooltip>
                          </template>
                        </v-checkbox>
                        <v-checkbox v-model="originalPurchaser">
                          <template v-slot:label>
                            You are the Original Purchaser
                            <v-tooltip bottom max-width="300px">
                              <template v-slot:activator="{ on }">
                                <v-icon color="primary" v-on="on">help_outline</v-icon>
                              </template>
                              <span>The stock must have been acquired directly from the issuing C-Corporation. Secondary sales (from other investors) do not qualify. Must have been purchased for money, services, or property (not stock).</span>
                            </v-tooltip>
                          </template>
                        </v-checkbox>
                        <v-checkbox v-model="grossAssets50">
                          <template v-slot:label>
                            Gross assets of $50 million or less
                            <v-tooltip bottom max-width="300px">
                              <template v-slot:activator="{ on }">
                                <v-icon color="primary" v-on="on">help_outline</v-icon>
                              </template>
                              <span>Never had gross assets in excess of $50M and does not have assets in excess of $50M immediately after issuance.</span>
                            </v-tooltip>
                          </template>
                        </v-checkbox>
                        <v-checkbox v-model="qualifiedBusiness">
                          <template v-slot:label>
                            Qualified business type?
                            <v-tooltip bottom max-width="300px">
                              <template v-slot:activator="{ on }">
                                <v-icon color="primary" v-on="on">help_outline</v-icon>
                              </template>
                              <span>Any business other than these disqualifying business types: (a) services firms such as health, law, engineering, architecture, accounting, actuarial science, performing arts, consulting, athletics, financial or brokerage services, (b) banking, insurance, financing and similar businesses, (c) farming, (d) mining and other natural resource businesses (e) operation of hotel, motel, restaurant or similar business.</span>
                            </v-tooltip>
                          </template>
                        </v-checkbox>

                        <v-checkbox v-model="activeBusiness">
                          <template v-slot:label>
                            Active business requirement
                            <v-tooltip bottom max-width="300px">
                              <template v-slot:activator="{ on }">
                                <v-icon color="primary" v-on="on">help_outline</v-icon>
                              </template>
                              <span>An entity that is actively running a business. In other words, at least 80% of the assets of the firm must be used to actively run the business, not for investment purposes.</span>
                            </v-tooltip>
                          </template>
                        </v-checkbox>
                        <v-checkbox v-model="after2010">
                          <template v-slot:label>
                            Stock acquired after Sept. 27, 2010
                            <v-tooltip bottom max-width="300px">
                              <template v-slot:activator="{ on }">
                                <v-icon color="primary" v-on="on">help_outline</v-icon>
                              </template>
                              <span>If purchased prior to this, QSBS may still qualify for a smaller percent exclusion. Must be equity ownership - i.e. pre-money SAFEs and Convertible Notes do not qualify as QSBS until the time of conversion.</span>
                            </v-tooltip>
                          </template>
                        </v-checkbox>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <!-- Section 1244 SBC Losses -->
                    <v-expansion-panel>
                      <v-expansion-panel-header>
                        <v-row no-gutters>
                          <v-col cols="8">Section 1244 (SBC Losses)</v-col>
                          <v-col cols="2">
                            <v-tooltip bottom max-width="300px">
                              <template v-slot:activator="{ on }">
                                <v-icon color="primary" v-on="on">help_outline</v-icon>
                              </template>
                              <span>Small Business Corporation (SBC) stock under 1244 allows investors to write off qualifying losses as ordinary income (up to $50k / $100k joint). Always consult with your tax professional or advisor as individual tax situations always differ. These requirements may not cover all nuances and situations.</span>
                            </v-tooltip>
                          </v-col>
                          <v-col cols="2">
                            <v-tooltip bottom max-width="300px">
                              <template v-slot:activator="{ on }">
                                <v-icon color="warning" v-on="on">warning</v-icon>
                              </template>
                              <span>Always consult with your tax professional or advisor as individual tax situations always differ. These requirements may not cover all nuances and situations.</span>
                            </v-tooltip>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        All of the conditions below must be met in order to potentially qualify as IRC Section 1244 stock. This Section may exempts Small Business Corporation (SBC) stock, allowing investors to write off qualifying losses as ordinary income (up to $50k single / $100k joint filing).
                        <v-checkbox v-model="domesticCorS">
                          <template v-slot:label>
                            Domestic C- or S-Corporation
                            <v-tooltip bottom max-width="300px">
                              <template v-slot:activator="{ on }">
                                <v-icon color="primary" v-on="on">help_outline</v-icon>
                              </template>
                              <span>The security must be equity in a Domestic Corporation (C or S). It cannot be a pre-money SAFE, Convertible Note, etc. It also cannot be an investment in an LLC. Note: the new YC post-money SAFEs since September 2018 may be treated as Common Stock under IRC Section 1202.</span>
                            </v-tooltip>
                          </template>
                        </v-checkbox>
                        <v-checkbox v-model="acquiredForCash">
                          <template v-slot:label>
                            Acquired for cash or property
                            <v-tooltip bottom max-width="300px">
                              <template v-slot:activator="{ on }">
                                <v-icon color="primary" v-on="on">help_outline</v-icon>
                              </template>
                              <span>Must have been directly acquired from the issuer (not a secondary sale) for cash or property. Note: 1244 stock differs from QSBS in that it CANNOT be acquired in return for services.</span>
                            </v-tooltip>
                          </template>
                        </v-checkbox>
                        <v-checkbox v-model="under1Mil">
                          <template v-slot:label>
                            Less than $1 million raised
                            <v-tooltip bottom max-width="300px">
                              <template v-slot:activator="{ on }">
                                <v-icon color="primary" v-on="on">help_outline</v-icon>
                              </template>
                              <span>If a business raises more than $1 million in a single raise, they must earmark the first million dollars as 1244 stock at the time of investment. Otherwise the 1244 deduction is allocated among all the capital raised.</span>
                            </v-tooltip>
                          </template>
                        </v-checkbox>
                        <v-checkbox v-model="revenueOver50">
                          <template v-slot:label>
                            50% of revenue from active sources
                            <v-tooltip bottom max-width="300px">
                              <template v-slot:activator="{ on }">
                                <v-icon color="primary" v-on="on">help_outline</v-icon>
                              </template>
                              <span>
                                <p>At least 50% of gross receipts from an active trade - e.g. not from royalties, rents, dividends, interest, annuities, and gains from sales and trades of stocks or securities during the past five tax years before the loss.</p>
                                <p>Even if the gross receipts test is passed, the company must be an operating company (i.e. not an investment or holding company).</p>
                              </span>
                            </v-tooltip>
                          </template>
                        </v-checkbox>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                      <v-expansion-panel-header>
                        <v-row no-gutters>
                          <v-col cols="8">Convertible Note - Conversion Taxes</v-col>
                          <v-col cols="2">
                            <v-tooltip bottom max-width="300px">
                              <template v-slot:activator="{ on }">
                                <v-icon color="primary" v-on="on">help_outline</v-icon>
                              </template>
                              <span>
                                <p>Always consult with your tax professional or advisor as individual tax situations always differ.</p>You may owe taxes on the interest payments on your note, even if you don't receive any cash payments, and even if all this interest converts to stock.
                              </span>
                            </v-tooltip>
                          </v-col>
                          <v-col cols="2">
                            <v-tooltip bottom max-width="300px">
                              <template v-slot:activator="{ on }">
                                <v-icon color="warning" v-on="on">warning</v-icon>
                              </template>
                              <span>Always consult with your tax professional or advisor as individual tax situations always differ. These requirements may not cover all nuances and situations.</span>
                            </v-tooltip>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        Convertible Notes vary widely and may result in taxable income, even if you don't receive any cash. If the terms of your Note require semi-annual or annual payments, you may receive a 1099 tax form and be required to pay taxes on this interest.
                        <v-checkbox
                          v-model="periodicPayments"
                          label="Semi / Annual Interest Payments?"
                        />More Convertible Note tax-tracking features in future releases.
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                  <v-textarea
                    label="Other notes - justification and proof of assets, etc."
                    v-model="taxNotes"
                    outlined
                  />

                  <v-card-actions>
                    <v-spacer />
                    <v-btn
                      class="primary"
                      @click="taxUpdate"
                      :disabled="!taxChanged"
                      :loading="isLoading"
                    >
                      <v-icon>save</v-icon>Save Changes
                    </v-btn>
                  </v-card-actions>
                </v-form>
              </v-col>
            </v-container>
          </v-row>
        </v-tab-item>
        <!-- Conversions - hidden if equity -->
        <v-tab-item v-if="!isEquity">
          <v-row justify="center">
            <v-container class="mx-3 px-2 py-0" style="max-width:600px">
              <v-col>
                <v-alert
                  type="info"
                >Important: after saving the conversion details below, use "Add Investment" to create the new security of Common or Preferred Stock and link to this security. This will ensure the current value and ROI is being tracked correctly.</v-alert>
                <v-form ref="conversionForm">
                  <v-checkbox v-model="isConverted" label="Security has converted to stock" />
                  <v-menu
                    v-model="convDateMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        :value="convPickedDate"
                        v-if="!converted.convertDate"
                        label="Conversion Date"
                        hint="YYYY-MM-DD format"
                        persistent-hint
                        prepend-icon="event"
                        readonly
                        @input="this.converted.convertDate = $event.target.value"
                        v-on="on"
                        outlined
                        :class="botSpacing"
                      />
                      <v-text-field
                        v-else
                        v-model="converted.convertDate"
                        label="Conversion Date"
                        hint="YYYY-MM-DD format"
                        persistent-hint
                        readonly
                        prepend-icon="event"
                        v-on="on"
                        outlined
                        :class="botSpacing"
                      />
                    </template>
                    <v-date-picker v-model="convPickedDate" no-title min="1990-01-01" />
                  </v-menu>
                  <v-text-field
                    prepend-icon="attach_money"
                    label="Conversion Price"
                    v-model="convertPrice"
                    type="number"
                    min="0"
                    prefix="$"
                    onkeypress="return event.charCode != 45"
                    outlined
                    hide-details
                    :class="botSpacing"
                  />
                  <v-text-field
                    prepend-icon="dynamic_feed"
                    label="Number of Shares"
                    v-model="convertShares"
                    type="number"
                    min="0"
                    onkeypress="return event.charCode != 45"
                    outlined
                    hide-details
                    :class="botSpacing"
                  />
                  <v-combobox
                    prepend-icon="merge_type"
                    label="Type of Converted Stock"
                    :items="['Common Stock','Preferred Stock']"
                    v-model="convertType"
                    outlined
                    hide-details
                    :class="botSpacing"
                  />
                  <v-textarea
                    label="Other notes"
                    v-model="convertNotes"
                    prepend-icon="note"
                    outlined
                    hide-details
                    :class="botSpacing"
                  />
                  <v-card-actions>
                    <v-spacer />

                    <v-btn
                      class="primary"
                      @click="conversionUpdate"
                      :disabled="!conversionChanged"
                      :loading="isLoading"
                    >
                      <v-icon>save</v-icon>Save Changes
                    </v-btn>
                  </v-card-actions>
                </v-form>
              </v-col>
            </v-container>
          </v-row>
        </v-tab-item>
        <!-- Exit Events -->
        <v-tab-item v-if="isExited">
          <v-row justify="center">
            <v-container class="mx-3 px-2 py-0" style="max-width:600px">
              <v-col>
                <v-form ref="exitForm">
                  <v-combobox
                    prepend-icon="date_range"
                    label="Type of Exit Event"
                    v-model="exitType"
                    :items="['M&A','IPO','Buyback','Type Other']"
                    outlined
                    :hide-details="true"
                    :class="botSpacing"
                  />
                  <v-menu
                    v-model="exitDateMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        :value="exitPickedDate"
                        v-if="!exitEvent.exitDate"
                        label="Exit Date"
                        hint="YYYY-MM-DD format"
                        persistent-hint
                        prepend-icon="event"
                        readonly
                        @input="this.exitEvent.exitDate = $event.target.value"
                        v-on="on"
                        outlined
                        :class="botSpacing"
                      />
                      <v-text-field
                        v-else
                        v-model="exitEvent.exitDate"
                        label="Exit Date"
                        hint="YYYY-MM-DD format"
                        persistent-hint
                        readonly
                        prepend-icon="event"
                        v-on="on"
                        outlined
                        :class="botSpacing"
                      />
                    </template>
                    <v-date-picker v-model="exitPickedDate" no-title min="1990-01-01" />
                  </v-menu>
                  <v-row align="center" justify="center" class="ml-0">
                    <v-text-field
                      prepend-icon="attach_money"
                      label="Exit Proceeds (or value in stock)"
                      v-model="exitProceeds"
                      type="number"
                      min="0"
                      prefix="$"
                      onkeypress="return event.charCode != 45"
                      outlined
                      :hide-details="true"
                      :class="botSpacing"
                    />
                    <v-spacer />Exit Mult. =
                    <v-chip v-if="ROI" :color="getColor(ROI)" dark>{{ ROI.toFixed(1) }} x</v-chip>
                    <v-spacer />IRR =
                    <v-chip v-if="IRR" :color="getIRRColor(IRR)" dark>{{ IRR.toFixed(1) }} %</v-chip>
                    <v-tooltip bottom max-width="300px">
                      <template v-slot:activator="{ on }">
                        <v-icon color="primary" v-on="on">help_outline</v-icon>
                      </template>
                      <span>The Internal Rate of Return (IRR) depends on four inputs: the Exit Date and Exit Value, as defined on this page, as well as the Original Purchase Date and Cost Basis (Purchase Value), on the Primary Details page.</span>
                    </v-tooltip>
                    <v-spacer />
                  </v-row>
                  <v-textarea
                    label="Other notes"
                    v-model="exitNotes"
                    prepend-icon="note"
                    outlined
                    :hide-details="true"
                    :class="botSpacing"
                  />
                  <v-card-actions>
                    <v-spacer />

                    <v-btn
                      class="primary"
                      @click="exitUpdate"
                      :disabled="!exitChanged"
                      :loading="isLoading"
                    >
                      <v-icon>save</v-icon>Save Changes
                    </v-btn>
                  </v-card-actions>
                </v-form>
              </v-col>
            </v-container>
          </v-row>
        </v-tab-item>
      </v-tabs>
    </v-container>
  </div>
</template>

<script>
// import { validationMixin } from "vuelidate";
import { mapGetters } from "vuex";
import constants from "@/constants.js";
import { ruleMixin } from "../components/mixins/ruleMixin.js";

export default {
  name: "EditInvestment",
  props: ["id"],
  mixins: [ruleMixin],
  // TODO: Check that errors display correctly (Vuex or local) for name Errors and validation
  validations: {
    // firstName: { required, minLength: minLength(1) },
    // lastName: { required, minLength: minLength(1) }
  },
  data: function() {
    return {
      active_tab: 0,
      isMobile: false,
      snackbar_update: false,
      botSpacing: "mb-3",
      securityTypes: [
        "SAFE",
        "Common Stock",
        "Preferred Stock",
        "Convertible Note",
        "Revenue Share",
        "(Or type to add new)"
      ],
      //For custom fields in other terms
      updateFieldValue: null,
      updateFieldLabel: null,
      customLabelPayload: {},
      customValuePayload: {},

      // Following for date picker
      menu2: false,
      matDateMenu: false,
      taxDateMenu: false,
      convDateMenu: false,
      exitDateMenu: false,
      //Primary Details
      primaryDetails: {
        // company_name: null,
        amount: null,
        portal: null,
        security: null,
        date: null,
        estValue: null,
        isExited: null,
        isFailed: null
      },
      moreDetails: {
        regType: null,
        accountType: null,
        isSecondary: null,
        dealStatus: null,
        dealFees: null,
        perks: null,
        perkStatus: null,
        dealNotes: null
      },
      terms: { ...constants.dealTerms },
      dd: { ...constants.dueDiligence },
      taxes: { ...constants.taxFields },
      converted: { ...constants.converted },
      exitEvent: { ...constants.exitEvent }
    };
  },
  computed: {
    ...mapGetters(["isLoading", "error"]),
    //Conditional Rendering of v-tabs on Edit Investment
    tabs() {
      let tabsList = [
        { name: "Primary Details" },
        { name: "More Details" },
        { name: "Deal Terms" },
        { name: "Due Diligence" },
        { name: "Taxes" }
      ];

      if (!this.isEquity) {
        tabsList.push({ name: "Conversions" });
      }
      if (this.isExited) {
        tabsList.push({ name: "Exit Event" });
      }
      return tabsList;
    },
    windowWidth() {
      return window.innerWidth;
    },
    ROI() {
      if (parseFloat(this.loadedExits.exitProceeds)) {
        let thisROI =
          parseFloat(this.loadedExits.exitProceeds) /
          parseFloat(this.loadedInvestment.amount);
        return thisROI;
      } else {
        return null;
      }
    },
    IRR() {
      if (
        parseFloat(this.loadedExits.exitProceeds) &&
        this.loadedExits.exitDate
      ) {
        let exitDay = new Date(this.loadedExits.exitDate);
        let purchaseDay = new Date(this.loadedInvestment.date);
        let mstoYear = 365 * 24 * 60 * 60 * 1000;
        let numYears = (exitDay.getTime() - purchaseDay.getTime()) / mstoYear;
        let expIRR = 1 / numYears;
        let thisIRR = Math.pow(this.ROI, expIRR) - 1;
        return thisIRR * 100;
      } else {
        return null;
      }
    },
    estROI() {
      if (parseFloat(this.loadedInvestment.estValue) != "") {
        let thisROI =
          parseFloat(this.loadedInvestment.estValue) /
          parseFloat(this.loadedInvestment.amount);
        return thisROI;
      } else {
        return null;
      }
    },
    estIRR() {
      if (parseFloat(this.loadedInvestment.estValue) != "") {
        let exitDay = new Date();
        let purchaseDay = new Date(this.loadedInvestment.date);
        let mstoYear = 365 * 24 * 60 * 60 * 1000;
        let numYears = (exitDay.getTime() - purchaseDay.getTime()) / mstoYear;
        let expIRR = 1 / numYears;
        let thisIRR = Math.pow(this.estROI, expIRR) - 1;
        return thisIRR * 100;
      } else {
        return null;
      }
    },
    loadedInvestments() {
      return this.$store.getters.loadedInvestments;
    },
    loadedInvestment() {
      return this.$store.getters.loadedInvestment(this.id);
    },
    loadedTerms() {
      return this.loadedInvestment.dealTerms;
    },
    loadedDueDiligence() {
      return this.loadedInvestment.dueDiligence;
    },
    loadedCustomFields() {
      let customFields = [];
      let tempFields = this.loadedInvestment.customFields;

      Object.entries(tempFields).forEach(([key, item]) => {
        //Transfer from Object-keys on server to list of objects with fieldID as prop
        let thisItem = {};
        thisItem.fieldID = key;
        thisItem.value = item.value;
        thisItem.label = item.label;
        customFields.push(thisItem);
      });
      return customFields;
    },
    loadedTaxes() {
      return this.loadedInvestment.taxes;
    },
    loadedConversion() {
      return this.loadedInvestment.converted;
    },
    loadedExits() {
      return this.loadedInvestment.exitEvent;
    },
    // Primary Details - check for any updates and then activate the Save Changes button
    primaryChanged() {
      if (
        this.primaryDetails.amount !== null &&
        this.primaryDetails.amount !== this.loadedInvestment.amount
      ) {
        return true;
      } else if (
        this.primaryDetails.portal !== null &&
        this.primaryDetails.portal !== this.loadedInvestment.portal
      ) {
        return true;
      } else if (
        this.primaryDetails.security !== null &&
        this.primaryDetails.security !== this.loadedInvestment.security
      ) {
        return true;
      } else if (
        this.primaryDetails.date !== null &&
        this.primaryDetails.date !== this.loadedInvestment.date
      ) {
        return true;
      } else if (
        this.primaryDetails.estValue !== null &&
        this.primaryDetails.estValue !== this.loadedInvestment.estValue
      ) {
        return true;
      } else if (
        this.primaryDetails.isExited !== null &&
        this.primaryDetails.isExited !== this.loadedInvestment.isExited
      ) {
        return true;
      } else if (
        this.primaryDetails.isFailed !== null &&
        this.primaryDetails.isFailed !== this.loadedInvestment.isFailed
      ) {
        return true;
      } else {
        return false;
      }
    },
    // Primary tab
    companyName: {
      get() {
        return this.loadedInvestment.company_name;
      },
      set(value) {
        this.primaryDetails.company_name = value;
      }
    },
    investmentAmount: {
      get() {
        return this.loadedInvestment.amount;
      },
      set(value) {
        this.primaryDetails.amount = value;
      }
    },
    fundingPortal: {
      get() {
        return this.loadedInvestment.portal;
      },
      set(value) {
        this.primaryDetails.portal = value;
      }
    },
    securityType: {
      get() {
        return this.loadedInvestment.security;
      },
      set(value) {
        this.primaryDetails.security = value;
      }
    },
    mainDate: {
      get() {
        return this.loadedInvestment.date;
      },
      set(value) {
        this.primaryDetails.date = value;
      }
    },
    estValue: {
      get() {
        return this.loadedInvestment.estValue;
      },
      set(value) {
        this.primaryDetails.estValue = value;
      }
    },
    isExited: {
      get() {
        return this.loadedInvestment.isExited;
      },
      set(value) {
        this.primaryDetails.isExited = value;
      }
    },
    isFailed: {
      get() {
        return this.loadedInvestment.isFailed;
      },
      set(value) {
        this.primaryDetails.isFailed = value;
      }
    },

    //More details page
    moreChanged() {
      //Objectkeys foreach doesn't work to loop through these and keep reactive - doesnt seem to loop at all
      if (
        this.moreDetails.regType !== null &&
        this.moreDetails.regType !== this.loadedInvestment.regType
      ) {
        return true;
      } else if (
        this.moreDetails.accountType !== null &&
        this.moreDetails.accountType !== this.loadedInvestment.accountType
      ) {
        return true;
      } else if (
        this.moreDetails.isSecondary !== null &&
        this.moreDetails.isSecondary !== this.loadedInvestment.isSecondary
      ) {
        return true;
      } else if (
        this.moreDetails.dealStatus !== null &&
        this.moreDetails.dealStatus !== this.loadedInvestment.dealStatus
      ) {
        return true;
      } else if (
        this.moreDetails.dealFees !== null &&
        this.moreDetails.dealFees !== this.loadedInvestment.dealFees
      ) {
        return true;
      } else if (
        this.moreDetails.perks !== null &&
        this.moreDetails.perks !== this.loadedInvestment.perks
      ) {
        return true;
      } else if (
        this.moreDetails.perkStatus !== null &&
        this.moreDetails.perkStatus !== this.loadedInvestment.perkStatus
      ) {
        return true;
      } else if (
        this.moreDetails.dealNotes !== null &&
        this.moreDetails.dealNotes !== this.loadedInvestment.dealNotes
      ) {
        return true;
      } else {
        return false;
      }
    },
    // More Details tab
    regType: {
      get() {
        return this.loadedInvestment.regType;
      },
      set(value) {
        this.moreDetails.regType = value;
      }
    },
    accountType: {
      get() {
        return this.loadedInvestment.accountType;
      },
      set(value) {
        this.moreDetails.accountType = value;
      }
    },
    isSecondary: {
      get() {
        return this.loadedInvestment.isSecondary;
      },
      set(value) {
        this.moreDetails.isSecondary = value;
      }
    },
    dealStatus: {
      get() {
        return this.loadedInvestment.dealStatus;
      },
      set(value) {
        this.moreDetails.dealStatus = value;
      }
    },
    dealFees: {
      get() {
        return this.loadedInvestment.dealFees;
      },
      set(value) {
        this.moreDetails.dealFees = value;
      }
    },
    perks: {
      get() {
        return this.loadedInvestment.perks;
      },
      set(value) {
        this.moreDetails.perks = value;
      }
    },
    perkStatus: {
      get() {
        return this.loadedInvestment.perkStatus;
      },
      set(value) {
        this.moreDetails.perkStatus = value;
      }
    },
    dealNotes: {
      get() {
        return this.loadedInvestment.dealNotes;
      },
      set(value) {
        this.moreDetails.dealNotes = value;
      }
    },
    // Deal Terms Tab
    isSAFE() {
      //Is the security updated? If so, return that value. Else, stay with Vuex store value
      return this.primaryDetails.security
        ? this.primaryDetails.security === "SAFE"
        : this.loadedInvestment.security === "SAFE";
    },
    isNote() {
      return this.primaryDetails.security
        ? this.primaryDetails.security === "Convertible Note"
        : this.loadedInvestment.security === "Convertible Note";
    },
    isRevShare() {
      return this.primaryDetails.security
        ? this.primaryDetails.security === "Revenue Share"
        : this.loadedInvestment.security === "Revenue Share";
    },
    isEquity() {
      let isCommon = false;
      let isPreferred = false;

      if (this.primaryDetails.security !== null) {
        isCommon = this.primaryDetails.security === "Common Stock";
        isPreferred = this.primaryDetails.security === "Preferred Stock";
      } else {
        isCommon = this.loadedInvestment.security === "Common Stock";
        isPreferred = this.loadedInvestment.security === "Preferred Stock";
      }

      if (isCommon || isPreferred) {
        return true;
      } else {
        return false;
      }
    },
    termsChanged() {
      if (
        this.terms.repurchaseRights !== null &&
        this.terms.repurchaseRights !== this.loadedTerms.repurchaseRights
      ) {
        return true;
      } else if (
        this.terms.proRata !== null &&
        this.terms.proRata !== this.loadedTerms.proRata
      ) {
        return true;
      } else if (
        this.terms.earlyBird !== null &&
        this.terms.earlyBird !== this.loadedTerms.earlyBird
      ) {
        return true;
      } else if (
        this.primaryDetails.security !== null &&
        this.primaryDetails.security !== this.loadedInvestment.security
      ) {
        return true;
      } else if (
        this.terms.dealTermsNotes !== null &&
        this.terms.dealTermsNotes !== this.loadedTerms.dealTermsNotes
      ) {
        return true;
      } else if (
        this.terms.valCap !== null &&
        this.terms.valCap !== this.loadedTerms.valCap
      ) {
        return true;
      } else if (
        this.terms.discountRate !== null &&
        this.terms.discountRate !== this.loadedTerms.discountRate
      ) {
        return true;
      } else if (
        this.terms.MFN !== null &&
        this.terms.MFN !== this.loadedTerms.MFN
      ) {
        return true;
      } else if (
        this.terms.postMoney !== null &&
        this.terms.postMoney !== this.loadedTerms.postMoney
      ) {
        return true;
      } else if (
        this.terms.preMoney !== null &&
        this.terms.preMoney !== this.loadedTerms.preMoney
      ) {
        return true;
      } else if (
        this.terms.sharePrice !== null &&
        this.terms.sharePrice !== this.loadedTerms.sharePrice
      ) {
        return true;
      } else if (
        this.terms.numShares !== null &&
        this.terms.numShares !== this.loadedTerms.numShares
      ) {
        return true;
      } else if (
        this.terms.liquidationPreference !== null &&
        this.terms.liquidationPreference !==
          this.loadedTerms.liquidationPreference
      ) {
        return true;
      } else if (
        this.terms.votingRights !== null &&
        this.terms.votingRights !== this.loadedTerms.votingRights
      ) {
        return true;
      } else if (
        this.terms.maturityDate !== null &&
        this.terms.maturityDate !== this.loadedTerms.maturityDate
      ) {
        return true;
      } else if (
        this.terms.interestRate !== null &&
        this.terms.interestRate !== this.loadedTerms.interestRate
      ) {
        return true;
      } else if (
        this.terms.revSharePercent !== null &&
        this.terms.revSharePercent !== this.loadedTerms.revSharePercent
      ) {
        return true;
      } else if (
        this.terms.revShareFreq !== null &&
        this.terms.revShareFreq !== this.loadedTerms.revShareFreq
      ) {
        return true;
      } else if (
        this.terms.repaymentCap !== null &&
        this.terms.repaymentCap !== this.loadedTerms.repaymentCap
      ) {
        return true;
      } else if (
        this.terms.isSecured !== null &&
        this.terms.isSecured !== this.loadedTerms.isSecured
      ) {
        return true;
      } else if (this.updateFieldValue) {
        return true;
      } else if (this.updateFieldLabel) {
        return true;
      } else {
        return false;
      }
    },

    //Deal Terms Getters and Setters
    //This is for the Security Type field on the Deal Terms page - want to be separate from Primary page
    securityTypeTerms: {
      get() {
        return this.loadedInvestment.security;
      },
      set(value) {
        this.primaryDetails.security = value;
      }
    },
    repurchaseRights: {
      get() {
        return this.loadedTerms.repurchaseRights;
      },
      set(value) {
        this.terms.repurchaseRights = value;
      }
    },
    proRata: {
      get() {
        return this.loadedTerms.proRata;
      },
      set(value) {
        this.terms.proRata = value;
      }
    },
    earlyBird: {
      get() {
        return this.loadedTerms.earlyBird;
      },
      set(value) {
        this.terms.earlyBird = value;
      }
    },
    dealTermsNotes: {
      get() {
        return this.loadedTerms.dealTermsNotes;
      },
      set(value) {
        this.terms.dealTermsNotes = value;
      }
    },
    valCap: {
      get() {
        return this.loadedTerms.valCap;
      },
      set(value) {
        this.terms.valCap = value;
      }
    },
    discountRate: {
      get() {
        return this.loadedTerms.discountRate;
      },
      set(value) {
        this.terms.discountRate = value;
      }
    },
    mfnClause: {
      get() {
        return this.loadedTerms.MFN;
      },
      set(value) {
        this.terms.MFN = value;
      }
    },
    pickedDate: {
      get() {
        return this.loadedTerms.maturityDate;
      },
      set(value) {
        this.terms.maturityDate = value;
      }
    },
    postMoney: {
      get() {
        return this.loadedTerms.postMoney;
      },
      set(value) {
        this.terms.postMoney = value;
      }
    },
    preMoney: {
      get() {
        return this.loadedTerms.preMoney;
      },
      set(value) {
        this.terms.preMoney = value;
      }
    },
    sharePrice: {
      get() {
        return this.loadedTerms.sharePrice;
      },
      set(value) {
        this.terms.sharePrice = value;
      }
    },
    numShares: {
      get() {
        return this.loadedTerms.numShares;
      },
      set(value) {
        this.terms.numShares = value;
      }
    },
    liquidationPreference: {
      get() {
        return this.loadedTerms.liquidationPreference;
      },
      set(value) {
        this.terms.liquidationPreference = value;
      }
    },
    votingRights: {
      get() {
        return this.loadedTerms.votingRights;
      },
      set(value) {
        this.terms.votingRights = value;
      }
    },
    interestRate: {
      get() {
        return this.loadedTerms.interestRate;
      },
      set(value) {
        this.terms.interestRate = value;
      }
    },
    revSharePercent: {
      get() {
        return this.loadedTerms.revSharePercent;
      },
      set(value) {
        this.terms.revSharePercent = value;
      }
    },
    revShareFreq: {
      get() {
        return this.loadedTerms.revShareFreq;
      },
      set(value) {
        this.terms.revShareFreq = value;
      }
    },
    repaymentCap: {
      get() {
        return this.loadedTerms.repaymentCap;
      },
      set(value) {
        this.terms.repaymentCap = value;
      }
    },
    isSecured: {
      get() {
        return this.loadedTerms.isSecured;
      },
      set(value) {
        this.terms.isSecured = value;
      }
    },

    //Due Diligence
    ddChanged() {
      if (
        this.dd.teamNotes !== null &&
        this.dd.teamNotes !== this.loadedDueDiligence.teamNotes
      ) {
        return true;
      } else if (
        this.dd.techNotes !== null &&
        this.dd.techNotes !== this.loadedDueDiligence.techNotes
      ) {
        return true;
      } else if (
        this.dd.tractionNotes !== null &&
        this.dd.tractionNotes !== this.loadedDueDiligence.tractionNotes
      ) {
        return true;
      } else if (
        this.dd.tamNotes !== null &&
        this.dd.tamNotes !== this.loadedDueDiligence.tamNotes
      ) {
        return true;
      } else if (
        this.dd.termsNotes !== null &&
        this.dd.termsNotes !== this.loadedDueDiligence.termsNotes
      ) {
        return true;
      } else if (
        this.dd.teamRating !== null &&
        this.dd.teamRating !== this.loadedDueDiligence.teamRating
      ) {
        return true;
      } else if (
        this.dd.techRating !== null &&
        this.dd.techRating !== this.loadedDueDiligence.techRating
      ) {
        return true;
      } else if (
        this.dd.tractionRating !== null &&
        this.dd.tractionRating !== this.loadedDueDiligence.tractionRating
      ) {
        return true;
      } else if (
        this.dd.tamRating !== null &&
        this.dd.tamRating !== this.loadedDueDiligence.tamRating
      ) {
        return true;
      } else if (
        this.dd.termsRating !== null &&
        this.dd.termsRating !== this.loadedDueDiligence.termsRating
      ) {
        return true;
      } else {
        return false;
      }
    },
    //Due Diligence Getters and Setters
    teamRating: {
      get() {
        return this.loadedDueDiligence.teamRating;
      },
      set(value) {
        this.dd.teamRating = value;
      }
    },
    techRating: {
      get() {
        return this.loadedDueDiligence.techRating;
      },
      set(value) {
        this.dd.techRating = value;
      }
    },
    tamRating: {
      get() {
        return this.loadedDueDiligence.tamRating;
      },
      set(value) {
        this.dd.tamRating = value;
      }
    },
    tractionRating: {
      get() {
        return this.loadedDueDiligence.tractionRating;
      },
      set(value) {
        this.dd.tractionRating = value;
      }
    },
    termsRating: {
      get() {
        return this.loadedDueDiligence.termsRating;
      },
      set(value) {
        this.dd.termsRating = value;
      }
    },
    teamNotes: {
      get() {
        return this.loadedDueDiligence.teamNotes;
      },
      set(value) {
        this.dd.teamNotes = value;
      }
    },
    techNotes: {
      get() {
        return this.loadedDueDiligence.techNotes;
      },
      set(value) {
        this.dd.techNotes = value;
      }
    },
    tamNotes: {
      get() {
        return this.loadedDueDiligence.tamNotes;
      },
      set(value) {
        this.dd.tamNotes = value;
      }
    },
    tractionNotes: {
      get() {
        return this.loadedDueDiligence.tractionNotes;
      },
      set(value) {
        this.dd.tractionNotes = value;
      }
    },
    termsNotes: {
      get() {
        return this.loadedDueDiligence.termsNotes;
      },
      set(value) {
        this.dd.termsNotes = value;
      }
    },
    // Taxes
    soldInvestments() {
      return this.$store.getters.loadedInvestmentsLiquidated;
    },
    is1045Rollover() {
      // Needed because is1045 get() doesn't update until taxes.is1045 is set and submitted to Vuex store
      if (this.loadedTaxes.is1045) {
        return this.loadedTaxes.is1045 ? true : false;
      } else {
        return this.taxes.is1045 ? true : false;
      }
    },
    is1045: {
      get() {
        //Requires is1045 is initialized as null obj prop to be reactive
        return this.loadedTaxes.is1045;
      },
      set(value) {
        this.taxes.is1045 = value;
      }
    },
    taxChanged() {
      if (
        this.taxes.is1045 !== null &&
        this.taxes.is1045 !== this.loadedTaxes.is1045
      ) {
        return true;
      } else if (
        this.taxes.priorRolloverID !== null &&
        this.taxes.priorRolloverID !== this.loadedTaxes.priorRolloverID
      ) {
        return true;
      } else if (
        this.taxes.purchaseDate !== null &&
        this.taxes.purchaseDate !== this.loadedTaxes.purchaseDate
      ) {
        return true;
      } else if (
        this.taxes.domesticCorp !== null &&
        this.taxes.domesticCorp !== this.loadedTaxes.domesticCorp
      ) {
        return true;
      } else if (
        this.taxes.originalPurchaser !== null &&
        this.taxes.originalPurchaser !== this.loadedTaxes.originalPurchaser
      ) {
        return true;
      } else if (
        this.taxes.grossAssets50 !== null &&
        this.taxes.grossAssets50 !== this.loadedTaxes.grossAssets50
      ) {
        return true;
      } else if (
        this.taxes.qualifiedBusiness !== null &&
        this.taxes.qualifiedBusiness !== this.loadedTaxes.qualifiedBusiness
      ) {
        return true;
      } else if (
        this.taxes.activeBusiness !== null &&
        this.taxes.activeBusiness !== this.loadedTaxes.activeBusiness
      ) {
        return true;
      } else if (
        this.taxes.after2010 !== null &&
        this.taxes.after2010 !== this.loadedTaxes.after2010
      ) {
        return true;
      } else if (
        this.taxes.domesticCorS !== null &&
        this.taxes.domesticCorS !== this.loadedTaxes.domesticCorS
      ) {
        return true;
      } else if (
        this.taxes.acquiredForCash !== null &&
        this.taxes.acquiredForCash !== this.loadedTaxes.acquiredForCash
      ) {
        return true;
      } else if (
        this.taxes.under1Mil !== null &&
        this.taxes.under1Mil !== this.loadedTaxes.under1Mil
      ) {
        return true;
      } else if (
        this.taxes.revenueOver50 !== null &&
        this.taxes.revenueOver50 !== this.loadedTaxes.revenueOver50
      ) {
        return true;
      } else if (
        this.taxes.periodicPayments !== null &&
        this.taxes.periodicPayments !== this.loadedTaxes.periodicPayments
      ) {
        return true;
      } else if (
        this.taxes.taxNotes !== null &&
        this.taxes.taxNotes !== this.loadedTaxes.taxNotes
      ) {
        return true;
      } else {
        return false;
      }
    },
    purchaseDate: {
      get() {
        return this.loadedTaxes.purchaseDate;
      },
      set(value) {
        this.taxes.purchaseDate = value;
      }
    },
    priorRolloverObj: {
      get() {
        if (this.loadedTaxes.priorRolloverID) {
          return this.$store.getters.loadedInvestment(
            this.loadedTaxes.priorRolloverID
          );
        } else {
          return null;
        }
      },
      set(value) {
        this.taxes.priorRolloverID = value.id;
      }
    },
    domesticCorp: {
      get() {
        return this.loadedTaxes.domesticCorp;
      },
      set(value) {
        this.taxes.domesticCorp = value;
      }
    },
    originalPurchaser: {
      get() {
        return this.loadedTaxes.originalPurchaser;
      },
      set(value) {
        this.taxes.originalPurchaser = value;
      }
    },
    grossAssets50: {
      get() {
        return this.loadedTaxes.grossAssets50;
      },
      set(value) {
        this.taxes.grossAssets50 = value;
      }
    },
    qualifiedBusiness: {
      get() {
        return this.loadedTaxes.qualifiedBusiness;
      },
      set(value) {
        this.taxes.qualifiedBusiness = value;
      }
    },
    activeBusiness: {
      get() {
        return this.loadedTaxes.activeBusiness;
      },
      set(value) {
        this.taxes.activeBusiness = value;
      }
    },
    after2010: {
      get() {
        return this.loadedTaxes.after2010;
      },
      set(value) {
        this.taxes.after2010 = value;
      }
    },
    domesticCorS: {
      get() {
        return this.loadedTaxes.domesticCorS;
      },
      set(value) {
        this.taxes.domesticCorS = value;
      }
    },
    acquiredForCash: {
      get() {
        return this.loadedTaxes.acquiredForCash;
      },
      set(value) {
        this.taxes.acquiredForCash = value;
      }
    },
    under1Mil: {
      get() {
        return this.loadedTaxes.under1Mil;
      },
      set(value) {
        this.taxes.under1Mil = value;
      }
    },
    revenueOver50: {
      get() {
        return this.loadedTaxes.revenueOver50;
      },
      set(value) {
        this.taxes.revenueOver50 = value;
      }
    },
    periodicPayments: {
      get() {
        return this.loadedTaxes.periodicPayments;
      },
      set(value) {
        this.taxes.periodicPayments = value;
      }
    },
    taxNotes: {
      get() {
        return this.loadedTaxes.taxNotes;
      },
      set(value) {
        this.taxes.taxNotes = value;
      }
    },
    pickedTaxDate: {
      get() {
        return this.loadedTaxes.purchaseDate;
      },
      set(value) {
        this.taxes.purchaseDate = value;
      }
    },

    // Conversions
    conversionChanged() {
      if (
        this.converted.isConverted !== null &&
        this.converted.isConverted !== this.loadedConversion.isConverted
      ) {
        return true;
      } else if (
        this.converted.convertDate !== null &&
        this.converted.convertDate !== this.loadedConversion.convertDate
      ) {
        return true;
      } else if (
        this.converted.convertType !== null &&
        this.converted.convertType !== this.loadedConversion.convertType
      ) {
        return true;
      } else if (
        this.converted.convertPrice !== null &&
        this.converted.convertPrice !== this.loadedConversion.convertPrice
      ) {
        return true;
      } else if (
        this.converted.convertShares !== null &&
        this.converted.convertShares !== this.loadedConversion.convertShares
      ) {
        return true;
      } else if (
        this.converted.convertNotes !== null &&
        this.converted.convertNotes !== this.loadedConversion.convertNotes
      ) {
        return true;
      } else {
        return false;
      }
    },
    isConverted: {
      get() {
        return this.loadedConversion.isConverted;
      },
      set(value) {
        this.converted.isConverted = value;
      }
    },
    convPickedDate: {
      get() {
        return this.loadedConversion.convertDate;
      },
      set(value) {
        this.converted.convertDate = value;
      }
    },
    convertType: {
      get() {
        return this.loadedConversion.convertType;
      },
      set(value) {
        this.converted.convertType = value;
      }
    },
    convertPrice: {
      get() {
        return this.loadedConversion.convertPrice;
      },
      set(value) {
        this.converted.convertPrice = value;
      }
    },
    convertShares: {
      get() {
        return this.loadedConversion.convertShares;
      },
      set(value) {
        this.converted.convertShares = value;
      }
    },
    convertNotes: {
      get() {
        return this.loadedConversion.convertNotes;
      },
      set(value) {
        this.converted.convertNotes = value;
      }
    },

    // Exit Events
    exitChanged() {
      if (
        this.exitEvent.exitType !== null &&
        this.exitEvent.exitType !== this.loadedExits.exitType
      ) {
        return true;
      } else if (
        this.exitEvent.exitDate !== null &&
        this.exitEvent.exitDate !== this.loadedExits.exitDate
      ) {
        return true;
      } else if (
        this.exitEvent.exitProceeds !== null &&
        this.exitEvent.exitProceeds !== this.loadedExits.exitProceeds
      ) {
        return true;
      } else if (
        this.exitEvent.exitNotes !== null &&
        this.exitEvent.exitNotes !== this.loadedExits.exitNotes
      ) {
        return true;
      } else {
        return false;
      }
    },
    exitType: {
      get() {
        return this.loadedExits.exitType;
      },
      set(value) {
        this.exitEvent.exitType = value;
      }
    },
    exitPickedDate: {
      get() {
        return this.loadedExits.exitDate;
      },
      set(value) {
        this.exitEvent.exitDate = value;
      }
    },
    exitProceeds: {
      get() {
        return this.loadedExits.exitProceeds;
      },
      set(value) {
        this.exitEvent.exitProceeds = value;
      }
    },
    exitNotes: {
      get() {
        return this.loadedExits.exitNotes;
      },
      set(value) {
        this.exitEvent.exitNotes = value;
      }
    }
  },
  created() {
    window.addEventListener("resize", this.onResize); //For conditional rendering of vertical vs. horizontal tabs
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize); //For conditional rendering of vertical vs. horizontal tabs
  },
  methods: {
    editCompany() {
      let thisCompany = this.$store.getters.loadedCompanies.find(
        company => company.company_name == this.loadedInvestment.company_name
      );
      this.$router.push("/companies/" + thisCompany.id);
    },
    gotoConversionsTab() {
      var index = this.tabs.findIndex(tab => tab.name == "Conversions"); //May be 5 or 6 depending on whether they added Exit or Conversions first
      this.active_tab = index;
    },
    gotoExitTab() {
      var index = this.tabs.findIndex(tab => tab.name == "Exit Event");
      this.active_tab = index;
    },
    onResize() {
      if (window.innerWidth > 780) {
        this.isMobile = false;
      } else {
        this.isMobile = true;
      }
    },
    getColor(thisROI) {
      if (thisROI == 0) return "red";
      else if (thisROI < 1.0) return "orange";
      else if (thisROI == 1.0) return "gray";
      else if (thisROI < 10.0) return "green";
      else return "blue";
    },
    getIRRColor(thisIRR) {
      if (thisIRR < 1.0) return "red";
      else if (thisIRR < 5.0) return "orange";
      else if (thisIRR < 15.0) return "green";
      else return "blue";
    },
    updateCustomFieldLabel(value, fieldID) {
      this.updateFieldLabel = true;
      this.customLabelPayload[fieldID] = {};
      this.customLabelPayload[fieldID].label = value;
      // this.customLabelPayload[fieldID].invID = this.id;
      // this.customLabelPayload[fieldID].compID = this.loadedInvestment.compID;
      // this.$store.dispatch("updateCustomField", payload);
    },
    updateCustomFieldValue(value, fieldID) {
      this.updateFieldValue = true;
      this.customValuePayload[fieldID] = {};
      this.customValuePayload[fieldID].value = value;
      // this.customValuePayload[fieldID].invID = this.id;
      // this.customValuePayload[fieldID].compID = this.loadedInvestment.compID;
    },
    newField() {
      const randID =
        Math.random()
          .toString(36)
          .substring(2, 15) +
        Math.random()
          .toString(36)
          .substring(2, 15);

      let payload = {};
      payload.fieldID = randID;
      payload.invID = this.id;
      payload.compID = this.loadedInvestment.compID;
      payload.value = "New Value";
      payload.label = "New Field";

      this.$store.dispatch("createCustomField", payload);
    },
    deleteCustomField(fieldID) {
      let payload = {};
      payload.fieldID = fieldID;
      payload.invID = this.id;
      payload.compID = this.loadedInvestment.compID;
      this.$store.dispatch("deleteCustomField", payload).then(() => {
        this.snackbar_update = true;
      });
    },
    primaryUpdate() {
      let primaryUpdates = {};

      primaryUpdates.id = this.id;
      primaryUpdates.compID = this.loadedInvestment.compID;

      Object.keys(this.primaryDetails).forEach(key => {
        if (this.primaryDetails[key] !== null) {
          primaryUpdates[key] = this.primaryDetails[key];
        }
      });

      this.$store.dispatch("updateInvestment", primaryUpdates).then(() => {
        this.snackbar_update = true;
      });

      // Reset temporary form states to blank
      Object.keys(this.primaryDetails).forEach(key => {
        this.primaryDetails[key] = null;
      });
    },
    moreUpdate() {
      let moreUpdates = {};

      moreUpdates.id = this.id;
      moreUpdates.compID = this.loadedInvestment.compID;

      Object.keys(this.moreDetails).forEach(key => {
        if (this.moreDetails[key] !== null) {
          moreUpdates[key] = this.moreDetails[key];
        }
      });

      this.$store.dispatch("updateInvestment", moreUpdates).then(() => {
        this.snackbar_update = true;
      });

      // Reset temporary form states to blank
      Object.keys(this.moreDetails).forEach(key => {
        this.moreDetails[key] = null;
      });
    },
    termsUpdate() {
      let termsUpdates = {};
      let dealTerms = {};

      termsUpdates.id = this.id;
      termsUpdates.compID = this.loadedInvestment.compID;

      Object.keys(this.terms).forEach(key => {
        if (this.terms[key] !== null) {
          dealTerms[key] = this.terms[key];
        }
      });

      if (this.primaryDetails.security !== null) {
        termsUpdates.security = this.primaryDetails.security;
      }

      if (Object.entries(dealTerms).length !== 0) {
        termsUpdates.dealTerms = dealTerms;
      }

      //Custom Fields
      if (
        Object.entries(this.customLabelPayload).length !== 0 ||
        Object.entries(this.customValuePayload).length !== 0
      ) {
        termsUpdates.customFields = {};
      }
      if (Object.entries(this.customLabelPayload).length !== 0) {
        Object.entries(this.customLabelPayload).forEach(([k, v]) => {
          //First time through, create new custom field
          termsUpdates.customFields[k] = Object.assign(
            {},
            termsUpdates.customFields[k],
            { ...v }
          );
        });
      }
      if (Object.entries(this.customValuePayload).length !== 0) {
        Object.entries(this.customValuePayload).forEach(([k, v]) => {
          if (termsUpdates.customFields[k] !== undefined) {
            //Check if new custom field exists
            termsUpdates.customFields[k] = Object.assign(
              {},
              termsUpdates.customFields[k],
              { ...v }
            );
          } else {
            termsUpdates.customFields[k] = Object.assign(
              {},
              termsUpdates.customFields[k],
              { ...v }
            );
          }
        });
      }

      //TODO: need to figure out how to deal with custom fields with Firestore - mutating store with each input right now

      this.$store.dispatch("updateInvestment", termsUpdates).then(() => {
        this.snackbar_update = true;
      });

      // Reset temporary form states to blank
      // this.terms = { ...constants.dealTerms }; Spread operator doesn't work for reactive triggering a re-render for maturity date
      Object.keys(this.terms).forEach(key => {
        this.terms[key] = null;
      });

      this.primaryDetails.security = null;
      this.updateFieldLabel = null;
      this.updateFieldValue = null;
      this.customLabelPayload = {};
      this.customValuePayload = {};
    },
    ddUpdate() {
      let ddUpdates = {};
      let dueDiligence = {};

      ddUpdates.id = this.id;
      ddUpdates.compID = this.loadedInvestment.compID;

      Object.keys(this.dd).forEach(key => {
        if (this.dd[key] !== null) {
          dueDiligence[key] = this.dd[key];
        }
      });

      if (dueDiligence) {
        ddUpdates.dueDiligence = dueDiligence;
      }

      this.$store.dispatch("updateInvestment", ddUpdates).then(() => {
        this.snackbar_update = true;
      });

      // Reset temporary form states to blank
      this.dd = { ...constants.dueDiligence };
    },
    taxUpdate() {
      let taxUpdates = {};
      let taxes = {};

      taxUpdates.id = this.id;
      taxUpdates.compID = this.loadedInvestment.compID;

      Object.keys(this.taxes).forEach(key => {
        if (this.taxes[key] !== null) {
          taxes[key] = this.taxes[key];
        }
      });

      if (taxes) {
        taxUpdates.taxes = taxes;
      }

      this.$store.dispatch("updateInvestment", taxUpdates).then(() => {
        this.snackbar_update = true;
      });

      //Reset Update Taxes Variables
      this.taxes = { ...constants.taxFields };
    },
    conversionUpdate() {
      let convertUpdates = {};
      let converted = {};

      convertUpdates.id = this.id;
      convertUpdates.compID = this.loadedInvestment.compID;

      Object.keys(this.converted).forEach(key => {
        if (this.converted[key] !== null) {
          converted[key] = this.converted[key];
        }
      });

      if (converted) {
        convertUpdates.converted = converted;
      }

      this.$store.dispatch("updateInvestment", convertUpdates).then(() => {
        this.snackbar_update = true;
      });

      //Reset Update Taxes Variables
      this.converted = { ...constants.converted };
    },
    exitUpdate() {
      let exitUpdates = {};
      let exitEvent = {};

      exitUpdates.id = this.id;
      exitUpdates.compID = this.loadedInvestment.compID;

      Object.keys(this.exitEvent).forEach(key => {
        if (this.exitEvent[key] !== null) {
          exitEvent[key] = this.exitEvent[key];
        }
      });

      if (exitEvent) {
        exitUpdates.exitEvent = exitEvent;
      }

      this.$store.dispatch("updateInvestment", exitUpdates).then(() => {
        this.snackbar_update = true;
      });

      //Reset Update Taxes Variables
      this.exitEvent = { ...constants.exitEvent };
    }
  }
};
</script>